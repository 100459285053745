.css-1deu1v4-MuiPaper-root-MuiCard-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    overflow: hidden;
    position: relative;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(0, 0, 0, 0.12);
    margin-right: 20%;
    margin-left: 20%;
}