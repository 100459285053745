/* AppLogo.module.css */
.first-word {
  text-transform: uppercase !important;
}
.formContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 1rem !important;
}

.closeBtn {
  border-radius: 25px;
}

.textField {
  margin-bottom: 1rem !important;
}

.roundedButton {
  border-radius: 20px !important;
  margin-top: 1rem !important;
}

.swal-confirm-button-class {
  background-color: #05b8ad !important; /* Change button color to your desired color */
  color: #ffff !important; /* Change button text color */
  border-color: #05b8ad !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.swal2-container {
  z-index: 9999 !important;
}


div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: 0 0 0 3px #05b8ad!important;
}

.gmnoprint{
margin: 10px !important;
}
.gmnoprint div button {
padding:10px!important;
}

.css-r74t1y-MuiButtonBase-root-MuiButton-root
{
  background-color: #05B8AD !important;
}

.css-1nxjz8y-MuiButtonBase-root-MuiButton-root
{
  background-color: #05B8AD !important;
}

.css-1g69c9r-MuiButtonBase-root-MuiButton-root{
background-color: #05B8AD !important;
}

/************************************** location view based CSS ********************************************/

.buttonStyle {
position: absolute;
top: 50%;
transform: translateY(-50%);
min-width: 42px!important;
height: 86px;
border: none;
cursor: pointer;
border-radius: 10px;
z-index: 0;
display: flex;
align-items: center;
justify-content: center;
transition: opacity 0s ease-in-out, left 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.available_data{
width: 24px;
height: 24px;
margin-right: 5px;
}

/**************************************************** Sweet Alert Design Code Start ********************************************************************/


div:where(.swal2-container) div:where(.swal2-popup) {
width: 28em !important;
border-radius: 10px !important;
font-family: "Gill Sans", sans-serif !important;
flex: 1 1 auto !important;
overflow-y: auto !important;
padding: 40px 10px !important;
margin-top: 8px !important;
margin-bottom: 8px !important;
font-size: 16px !important;
}

div:where(.swal2-icon).swal2-warning {
width: 3.5em !important;
height: 3.5em !important;
border: 3px solid #f8bb86 !important;
margin: 0.5em auto .6em !important;
}

div:where(.swal2-icon).swal2-success {
border: 3px solid #a5dc86 !important;
margin: 0.5em auto .6em !important;
/* width: 4em; */
/* height: 4em; */
}

div:where(.swal2-icon) .swal2-icon-content {
font-size: 2.75em !important;
color: #e99953 !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
position: relative !important;
max-width: 100% !important;
padding: .5em 1em 0 !important;
color: rgb(27, 27, 27) !important;
font-size: 22px !important;
font-weight: 600 !important;
text-align: center !important;
text-transform: lowercase !important;
word-wrap: break-word !important;
}

.swal2-html-container::first-letter {
text-transform: capitalize !important;
}

.swal2-html-container {
text-transform: lowercase !important;
}

h2:where(.swal2-title)::first-letter {
text-transform: capitalize !important;
}

div:where(.swal2-container) .swal2-html-container {
color: rgb(63, 62, 62) !important;
font-size: 18px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm  {
background-color: #05b8ad !important;
color: #ffff !important;
border-radius: 10px !important;
font-size: 15px !important;
padding: 10px 15px !important;
box-shadow: 0 4px 8px rgba(0.2, 0.2, 0.2, 0.3) !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
border-radius: 10px !important;
font-size: 15px !important;
padding: 10px 15px !important;
background-color: #202222b9 !important;
/* padding: 12px 12px; */
box-shadow: 0 4px 8px rgba(0.2, 0.2, 0.2, 0.3) !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
box-shadow: 0 4px 8px rgba(0.2, 0.2, 0.2, 0.3) !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel:focus {
box-shadow: 0 4px 8px rgba(0.2, 0.2, 0.2, 0.3) !important;
}

.css-19dhcaf-MuiFormLabel-root-MuiInputLabel-root{
text-transform: lowercase;
}

.css-19dhcaf-MuiFormLabel-root-MuiInputLabel-root::first-letter{
text-transform: capitalize;
}

.css-cf88q6-MuiFormLabel-root-MuiInputLabel-root{
text-transform: lowercase;
}

.css-cf88q6-MuiFormLabel-root-MuiInputLabel-root::first-letter{
text-transform: capitalize;
}


/**************************************************** Sweet Alert Design Code End ********************************************************************/


.contact-info {
text-align: center;
margin-top: 3rem;
font-size: 16px;
color: gray;
}

.container {
font-family: Poppins;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
min-height: 100vh;
}

.welcome-message {
text-align: center;
}

.content-wrapper {
width: 90%;
max-width: 650px;
background-color: white;
margin-top: 3rem;
margin-bottom: 3rem;
border-radius: 30px;
padding: 3rem;
}

.logo {
text-align: center;
}

/* styles.css */
/* Add this to your CSS file to ensure list items show bullets */
ul {
list-style-type: disc; /* or circle, square depending on your design */
margin-left: 20px; /* Add some left margin for indentation */
}

ol {
list-style-type: decimal; /* Adjust as needed */
margin-left: 20px; /* Add some left margin for indentation */
}

li {
margin-bottom: 0.5em; /* Space between list items */
}


